<template>
  <div class="yandex-map">
    <!-- <div id="g-map"></div> -->
    <div id="yandex-map"></div>
    <img
      class="location-icon"
      src="../../assets/imgs/location_icon.png"
      alt=""
    />
    <!-- <div class="location" @click="onLocation">
          <img src="../../assets/imgs/location.png" alt="">
        </div> -->
  </div>
</template>

    <script>
import { mapState } from "vuex"
let mapContents
let gMapTimer
let marker
let myMarker
let infoWindow
export default {
  props: {
    apikey: {
      type: String,
      default: process.env.VUE_APP_YANDEXMAP_APIKEY
    },
    latLng: {
      type: Array,
      default: () => {
        return []
      }
    },
    locationList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    ...mapState(["lang"]),
    i18n() {
      return this.$t("map")
    }
  },
  data() {
    return {
      mapContents: null,
      times: 0,
      latitude: 25.229762,
      longitude: 55.289311,
      centerChange: false
    }
  },
  watch: {
    latLng(val, oldVal) {
      if (val && val.length === 2) {
        this.latitude = val[0]
        this.longitude = val[1]
        if (mapContents && !this.centerChange) {
          const center = { lat: this.latitude, lng: this.longitude }
          //   mapContents.setZoom(16)
          //   mapContents.setCenter(center)
        }
      }
    },
    locationList(val) {
      const list = []
      if (val.length) {
        val.map((item, index) => {
          list.push([
            { lat: Number(item.latitude), lng: Number(item.longitude) },
            item
          ])
        })
        this.setMarker(list)
      } else {
        console.log(window.marker)
        if (window.marker) {
          marker.setMap(null)
        }
        this.$toast(this.i18n.result_msg)
      }
    }
  },
  methods: {
    async initMap() {
      if (document.getElementById("g-map")) {
        this.times = 0
        console.log("gmapcontext")
        // The location of Uluru
        const center = { lat: this.latitude, lng: this.longitude }
        // The map, centered at Uluru
        mapContents = new google.maps.Map(document.getElementById("g-map"), {
          zoom: 12,
          center: center,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: true,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          clickableIcons: false
        })
        // console.log('center', center)
        mapContents.setCenter(center)
        infoWindow = new google.maps.InfoWindow()
        mapContents.addListener("click", (event) => {
          console.log("google map tap", event.latLng.lat(), event.latLng.lng())
          // this.onMap(event)
          this.$emit("onmap")
        })
        mapContents.addListener("center_changed", () => {
          this.onCenterChange()
          // console.log('center_changed')
        })
        mapContents.addListener("zoom_changed", () => {
          this.onRegionChange()
          // console.log('zoom_changed')
        })
        this.onLocation()
      } else if (document.getElementById("yandex-map")) {
        this.times = 0
        await ymaps3.ready
        const {
          YMap,
          YMapListener,
          YMapDefaultSchemeLayer,
          YMapDefaultFeaturesLayer
        } = ymaps3

        const center = [this.longitude, this.latitude]
        mapContents = new YMap(document.getElementById("yandex-map"), {
          location: {
            zoom: 12,
            center: center,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            clickableIcons: false
          }
        })
        const mapListener = new YMapListener({
          layer: "any",
          onClick: (evt) => this.$emit("onmap", evt)
          //   onMouseMove: (evt) => this.$emit("onmap", evt)
        })
        mapContents.addChild(mapListener)

        mapContents.addChild(new YMapDefaultSchemeLayer())
        mapContents.addChild(new YMapDefaultFeaturesLayer())

        this.onLocation()
      } else {
        this.times++
        console.log("gmaptimeout")
        gMapTimer = setTimeout(this.initMap, 500 * this.times)
      }
    },
    onCenterChange(e) {
      if (!mapContents) {
        return
      }
      this.centerChange = true
      // console.log([mapContents.getCenter().lat(), mapContents.getCenter().lng()])
      this.$emit("center", [
        mapContents.getCenter().lat(),
        mapContents.getCenter().lng()
      ])
      this.$emit("setItem", null)
      setTimeout(() => {
        this.centerChange = false
      }, 100)
    },
    onRegionChange(e) {
      if (!mapContents) {
        return
      }
      console.log(mapContents.getZoom())
      const zoomLevel = (mapContents.getZoom() / 2 - 2).toFixed(0)
      this.$emit("zoom", zoomLevel > 4 ? zoomLevel : 4)
    },
    onLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
            // console.log(pos)
            // mapContents.setCenter(pos)
            mapContents.setLocation({
              center: [pos.lng, pos.lat]
            })
            this.setMarker(pos)
            this.latitude = pos.lat
            this.longitude = pos.lng
            this.$emit("center", [pos.lng, pos.lat])
            // this.$emit('center', [22.631123, 113.820622])
          },
          (err) => {
            console.log(err)
            this.$emit("center", this.latLng)
            // this.$confirm({
            //   title: this.i18n.msg_title,
            //   content: 'Error: The Geolocation service failed.',
            //   showCancel: false
            // }).then(() => { })
          }
        )
      } else {
        // this.$toast(this.i18n.no_support)
      }
    },
    setMarker(pos) {
      if (!mapContents) {
        return
      }
      const { YMapMarker } = ymaps3
      console.log("set marker", pos)
      if (Array.isArray(pos)) {
        pos.forEach(([position, item], i) => {
          const markerElement = document.createElement("img")
          markerElement.className = "icon_maps_marker"
          markerElement.src = require("../../assets/imgs/marker0.png")
          markerElement.onclick = () => this.$emit("setItem", item)
          const marker = new YMapMarker(
            { coordinates: [position.lng, position.lat] },
            markerElement
          )
          mapContents.addChild(marker)
        })
      } else {
        const content = document.createElement("div")
        const marker = new YMapMarker(
          { coordinates: [pos.lng, pos.lat], draggable: true },
          content
        )
        mapContents.addChild(marker)
        const myLocation = require("../../assets/imgs/icon_home_location.png")
        content.innerHTML = `<img src="${myLocation}" style="width: 28px;height: 32px;" />
        <span style="font-size: 13px;display: flex;width: max-content; transform: translateX(-50%);">${this.i18n.my_position}</span>`
      }
      //   const image = {
      //     url: `${process.env.VUE_APP_DOMAIN}`,
      //     scaledSize: new ymaps3.maps.Size(25, 36),
      //     origin: new ymaps3.maps.Point(0, 0),
      //     anchor: new ymaps3.maps.Point(10, 30)
      //   }
      //   if (Array.isArray(pos)) {
      //     pos.forEach(([position, item], i) => {
      //       const markers = new ymaps3.maps.Marker({
      //         position,
      //         map: mapContents,
      //         optimized: false,
      //         icon: image
      //         // zIndex: i + 1
      //       })
      //       // Add a click listener for each marker, and set up the info window.
      //       markers.addListener("click", () => {
      //         // console.log('点击标记了', markers.getTitle())
      //         this.$emit("setItem", item)
      //       })
      //     })
      //   } else {
      //     myMarker = new ymaps3.maps.Marker({
      //       position: pos,
      //       map: mapContents,
      //       label: this.i18n.my_position
      //     })
      //   }
    },
    importMap() {
      if (!mapContents && !window.ymaps3) {
        console.log("gmap import")
        let language = "en_US"
        if (this.lang !== "en-US") {
          language = "zh-CN"
        }
        const src = `https://api-maps.yandex.ru/v3/?apikey=${this.apikey}&lang=${language}`
        this.createScript(src)
      } else {
        console.log("gmap init")
        this.initMap()
      }
    },
    createScript(src) {
      let oscript = window.document.createElement("script")
      oscript.id = `g-map-script-${this.lang}`
      oscript.src = src
      oscript.onerror = (error) => {
        console.log("yandex map load fail", error)
      }
      oscript.onload = () => {
        this.initMap()
      }
      let head =
        window.document.head ||
        window.document.getElementsByTagName("head")[0] ||
        window.document.documentElement
      head.insertBefore(oscript, head.firstChild)
    }
  },
  created() {
    window.initMap = this.initMap
    this.latitude = this.latLng[0]
    this.longitude = this.latLng[1]
  },
  mounted() {
    console.log("yandex map mounted")
    this.importMap()
  },
  beforeDestroy() {
    console.log("yandex map destroyed")
    if (mapContents) {
      mapContents = null
    }
    if (gMapTimer) {
      clearTimeout(gMapTimer)
    }
  }
}
</script>

<style lang="less" scoped>
#g-map,
#yandex-map,
.yandex-map {
  height: 100%;
  width: 100%;
}

.location {
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 120px;
  right: 80px;

  img {
    width: 100px;
    height: 100px;
  }
}

.location-icon {
  position: absolute;
  width: 36px;
  height: 58px;
  bottom: 50%;
  left: calc(50% - 18px);
}
</style>
<style lang="less">
.icon_maps_marker {
  width: 48px;
}
</style>
